<template>
    <div class="bg-gray-200">
        <div v-if="false" class="relative">
            <Map3D />
        </div>
        <div class="relative pt-20 lg:pt-16 overflow-hidden">
            <!-- BACKGROUND LAYERS -->
            <div
                class="z-1 bg-illustration illustration-sun w-[250px] h-[250px] sm:w-[400px] sm:h-[400px] top-0 -right-8 sm:right-24"
                v-lazy-background :lazy-background="require('~/assets/images/illustrations/sun.png')"
            ></div>
            <div
                class="hidden sm:block z-1 bg-illustration -top-4 sm:top-44 left-1/2 w-[40rem] sm:w-[110rem] h-[44rem] 2xl:w-[165rem] 2xl:h-[66rem] opacity-30"
                style="transform: translateX(-50%) rotateY(180deg)"
                v-lazy-background :lazy-background="require('~/assets/images/shapes/shape-3_gray-500.png')"
            ></div>
            <div
                class="sm:hidden z-1 bg-illustration -top-4 sm:top-44 left-1/2 w-[40rem] sm:w-[110rem] h-[44rem] 2xl:w-[165rem] 2xl:h-[66rem] opacity-30"
                style="transform: translateX(-50%) rotateY(180deg)"
                v-lazy-background :lazy-background="require('~/assets/images/shapes/shape-3_gray-500_mobile.png')"
            ></div>

            <div
                class="z-1 bg-illustration illustration-cloud-1 w-64 h-16 sm:w-96 sm:h-20 top-16 sm:top-12 -left-24 sm:left-24"
                v-lazy-background :lazy-background="require('~/assets/images/illustrations/cloud3.png')"
            ></div>
            <div
                class="z-1 bg-illustration illustration-cloud-2 w-64 h-16 sm:w-96 sm:h-20 top-32 sm:top-20 -right-24"
                v-lazy-background :lazy-background="require('~/assets/images/illustrations/cloud3.png')"
            ></div>

            <!--            <div class="bg-illustration">-->
            <!--                <object-->
            <!--                    class="w-[15rem] mb-12"-->
            <!--                    type="image/svg+xml"-->
            <!--                    :data="require('~/assets/images/logos/liget-logo-full.svg')"-->
            <!--                ></object>-->
            <!--            </div>-->

            <!-- * -->
            <!-- * -->
            <!-- * -->
            <!-- SEARCH -->
            <!-- * -->

            <div class="container grid grid-cols-12 base-responsive-gap relative z-10 pb-28 lg:pb-0">
                <div id="search-section-anchor" class="absolute left-0 top-0 -mt-28"></div>
                <div
                    class="z-1 bg-illustration -bottom-4 left-1/2 w-[40rem] sm:w-[110rem] h-[44rem] 2xl:w-[165rem] 2xl:h-[66rem] opacity-30 sm:hidden"
                    style="transform: translateX(-50%) rotateX(180deg); background-position: top center"
                    v-lazy-background :lazy-background="require('~/assets/images/shapes/shape-3_gray-500_mobile.png')"
                ></div>
                <div
                    class="bg-illustration top-20 sm:top-20 md:top-16 lg:top-12 xl:top-20 2xl:top-[70px] left-[calc(20%-4rem)] sm:left-24 xl:-left-4 2xl:-left-12 w-20 h-20 lg:w-24 lg:h-24"
                    v-lazy-background :lazy-background="require('~/assets/images/illustrations/bicycle.svg')"
                ></div>
                <div
                    class="bg-illustration bottom-4 lg:bottom-[unset] lg:top-[240px] xl:top-[240px] 2xl:top-[260px] right-8 2xl:right-36 w-24 h-16 lg:w-28 lg:h-24"
                    v-lazy-background :lazy-background="require('~/assets/images/illustrations/picnic-woman.svg')"
                ></div>
                <div
                    class="bg-illustration top-28 sm:top-16 md:top-[55px] lg:top-16 xl:top-[60px] 2xl:top-[72px] right-20 sm:right-[unset] sm:left-8 lg:left-4 xl:left-52 w-8 h-16"
                    v-lazy-background :lazy-background="require('~/assets/images/illustrations/tree1.svg')"
                ></div>
                <div
                    class="bg-illustration bottom-6 lg:bottom-[unset] left-12 sm:left-32 lg:left-[unset] lg:right-4 lg:top-[175px] xl:top-[170px] 2xl:top-[240px] w-6 h-14 sm:w-8 sm:h-16"
                    v-lazy-background :lazy-background="require('~/assets/images/illustrations/tree2.svg')"
                ></div>

                <h1
                    class="col-span-12 md:col-span-8 md:col-start-3 lg:col-span-6 lg:col-start-4 font-Poppins font-semibold text-xl sm:text-2xl md:text-3xl lg:text-4xl text-center h-12 lg:h-20 overflow-visible"
                >
                    <T t="index.heading" />
                </h1>
                <div class="col-span-12 lg:col-span-10 lg:col-start-2 mt-32 lg:mt-12 2xl:mt-16 z-50">
                    <EventSearchForm />
                </div>
            </div>

            <section>
                <!-- * -->
                <!-- * -->
                <!-- * -->
                <!-- FEATURED EVENTS -->
                <!-- * -->
                <div class="relative z-2 mt-16 sm:mt-20">
                    <div
                        class="absolute bg-contain bg-center bg-no-repeat top-72 right-0 w-1/2 h-[65rem] z-1"
                        style="background-position: top right"
                        v-lazy-background :lazy-background="require('~/assets/images/shapes/wave-lines.svg')"
                    ></div>

                    <div class="container relative z-2">
                        <h2 class="header-1 mb-8 sm:mb-12">
                            <T t="index.featured_programs_section_title" />
                        </h2>
                        <div class="grid grid-cols-12 card-grid-responsive-gap">
                            <div
                                v-for="(event, i) in featuredEvents"
                                :key="`featured-program-${i}`"
                                class="col-span-12"
                                :class="{ 'lg:col-span-5 ': i > 0, 'lg:col-start-2': i === 1, 'lg:pt-24': i === 2 }"
                            >
                                <FeaturedProgramCard
                                    :jumbo="i === 0"
                                    :date="$getFormattedEventInterval(event)"
                                    :description="event.lead_text"
                                    :event-types="event.categories_id"
                                    :img="$getMediaImage(event.media, 'lead')"
                                    :location="$getEventLocationName(event.location_id)"
                                    :url="$getEventUrl(event)"
                                    :ticket-url="event.ticket_url"
                                    :registration-url="event.registration_url"
                                    :title="event.name"
                                    :has-series="!!event.series"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <!-- * -->
                <!-- * -->
                <!-- * -->
                <!-- PROGRAM CAROUSEL -->
                <!-- * -->
                <div class="relative z-2 mt-12 sm:mt-16 lg:mt-20 pb-12 lg:pb-24">
                    <div class="container pb-12 sm:pb-24">
                        <div class="swiper-container mx-[-10px] sm:mx-0 overflow-hidden">
                            <div ref="programsCarousel" class="swiper container">
                                <div class="swiper-wrapper">
                                    <div
                                        v-for="(event, i) in recommendedEvents"
                                        :key="`recommended-event-${i}`"
                                        class="swiper-slide"
                                    >
                                        <ProgramRecommendationCard
                                            basic
                                            :date="$getFormattedEventInterval(event)"
                                            :img="$getMediaImage(event.media, 'lead', 'sm')"
                                            :location="$getEventLocationName(event.location_id)"
                                            :url="$getEventUrl(event)"
                                            :title="event.name"
                                            :has-series="!!event.series"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <SectionCloserLinkBar
                        slug="programs-in-varosliget"
                        :label="$t('index.all_programs_marquee_text')"
                    />
                </div>
            </section>

            <!-- * -->
            <!-- * -->
            <!-- * -->
            <!-- PROMO CAROUSEL -->
            <!-- * -->

            <div v-if="galleryItems?.length" class="relative z-1">
                <div
                    class="hidden sm:block absolute bg-cover lg:bg-contain bg-center bg-no-repeat top-12 left-0 -translate-y-full w-full h-[100vw] z-1"
                    style="background-position: bottom center"
                    v-lazy-background :lazy-background="require('~/assets/images/shapes/gradients/rasterized-gradient-yellow-400.png')"
                ></div>
                <div
                    class="sm:hidden absolute bg-cover lg:bg-contain bg-center bg-no-repeat top-12 left-0 -translate-y-full w-full h-[100vw] z-1"
                    style="background-position: bottom center"
                    v-lazy-background :lazy-background="
                        require('~/assets/images/shapes/gradients/rasterized-gradient-yellow-400_mobile.png')
                    "
                ></div>
                <PromoCarousel :items="galleryItems" fullscreen class="relative z-2" />
            </div>

            <!-- * -->
            <!-- * -->
            <!-- * -->
            <!-- MAGAZINE -->
            <!-- * -->

            <section v-if="featuredNews.length" class="mt-20">
                <div class="container">
                    <div class="mb-8 sm:mb-12">
                        <h2 class="header-1">
                            <T t="index.news_section_title" />
                        </h2>
                        <p><T t="index.news_section_subtitle" /></p>
                    </div>
                    <FeaturedArticlesGroupBlock :articles="featuredNews" />
                </div>

                <SectionCloserLinkBar slug="magazine" :label="$t('index.all_news_marquee_text')" class="mt-24" />
            </section>
        </div>
    </div>
</template>

<script>
import FeaturedArticlesGroupBlock from '~/components/Blocks/FeaturedArticlesGroupBlock/FeaturedArticlesGroupBlock.vue';
import FeaturedProgramCard from '~/components/Cards/FeaturedProgramCard/FeaturedProgramCard.vue';
import ProgramRecommendationCard from '~/components/Cards/ProgramRecommendationCard/ProgramRecommendationCard.vue';
import SectionCloserLinkBar from '~/components/Generic/SectionCloserLinkBar.vue';
import PromoCarousel from '~/components/Carousels/PromoCarousel/PromoCarousel.vue';
import Map3D from '~/components/Map/Map3D.vue';
import EventSearchForm from '~/components/Forms/EventSearchForm.vue';

export default {
    name: 'Index',
    components: {
        FeaturedArticlesGroupBlock,
        FeaturedProgramCard,
        ProgramRecommendationCard,
        SectionCloserLinkBar,
        PromoCarousel,
        Map3D,
        EventSearchForm,
    },
    data() {
        return {
            galleryItems: [],
            carousel: null,
        };
    },
    async fetch() {
        await this.$axios.$get('/home-carousels').then((res) => {
            this.galleryItems = res.carousels;
        });
    },
    fetchOnServer: false,
    head() {
        return {
            title: this.$getPageTitle('index'),
            meta: this.$getPageMetaData('index'),
        };
    },
    computed: {
        featuredNews() {
            return this.$store.state.data.homepageHighlightedNews;
        },
        featuredEvents() {
            return this.$store.state.data.recommendedEvents.slice(0, 3);
        },
        recommendedEvents() {
            return this.$store.state.data.recommendedEvents.slice(3, 7);
        },
    },
    mounted() {
        this.carousel = new this.$swiper(this.$refs.programsCarousel, {
            spaceBetween: 16,
            slidesPerView: 1.1,
            centeredSlides: false,
            loop: false,
            breakpoints: {
                480: {
                    spaceBetween: 16,
                    slidesPerView: 2.2,
                },
                640: {
                    spaceBetween: 16,
                    slidesPerView: 3,
                },
                1024: {
                    spaceBetween: 30,
                    slidesPerView: 4,
                },
                1920: {
                    spaceBetween: 30,
                    slidesPerView: 4,
                },
            },
        });
    },
    beforeDestroy() {
        this.carousel.destroy();
    },
};
</script>

<style scoped></style>
